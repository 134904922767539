/*eslint-disable */
// import GPathCareerModel from '../../Model/GPathCareer'
import request from '../../Utils/curl'

let GPathCareers = {
  /**
   * gpath_careerList
   */
  async gpath_careerList (context, whereFilter=null) {
    try{
      let post_data = new FormData();  
      if (whereFilter) {
        post_data.append('filter', JSON.stringify(whereFilter))
      }

      return await request.curl(context, "gpath_careers_list", post_data)
        .then(async response => {
          return response
        });

    }
    catch(err){
      console.error('Exception occurred at gpath_careerList() and Exception:',err.message)
    }
  },

  /**
   * gpath_careerView
   */
  async gpath_careerView (context, gpathCareerId) {
    try {      
      let post_data = new FormData();
      post_data.append('gpc_id', gpathCareerId);
      return await request.curl(context, "gpath_careers_view", post_data)
      .then(async response => {
        return response;
      });
    } 
    catch (err) {
      console.error("Exception occurred at gpath_careerView() and Exception:",err.message)
    }    
  },

  /**
   * gpath_careerAdd
   */
  async gpath_careerAdd (context, gpath_careerObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_careerObj) {
        post_data.append(key, gpath_careerObj[key]);
      }

      return await request.curl(context, "gpath_careers_add", post_data)
      .then(async response => {
          return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_careerAdd() and Exception:',err.message)
    }
  },

  /**
   * gpath_careerEdit
   */
  async gpath_careerEdit (context, gpath_careerObj) {
    try{
      let post_data = new FormData();
    
      for (let key in gpath_careerObj) {
        post_data.append(key, gpath_careerObj[key]);
      }

      return await request.curl(context, "gpath_careers_edit", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.error('Exception occurred at gpath_careerEdit() and Exception:',err.message)
    }
  },

  /**
   * gpath_careerDelete
   */
  async gpath_careerDelete (context, gpathCareerId) {
    try{
      let post_data = new FormData();
      
      post_data.append('gpc_id', gpathCareerId);

      return await request.curl(context, "gpath_careers_delete", post_data)
      .then(async response => {
        return response;
      });
    }
    catch(err){
      console.log('Exception occurred at gpath_careerDelete() and Exception:',err.message)
    }
  }
}

export {
  GPathCareers
}
